@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
  --main-color: darkcyan;
  --bg-color: #fafafa;
}

body {
  font-size: 1.5em;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  max-height: 100%;
  margin: 0;
  background-color: var(--bg-color);
  overflow: hidden;
}

#container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h1 {
  margin-bottom: 10px;
  letter-spacing: 3px;
  font-weight: 400;
  font-size: calc(35px + (60 - 35) * ((100vw - 320px) / (1100 - 320)));
}

@media screen and (min-width: 1100px) {
  h1 {
    font-size: 60px;
  }
}

#plural {
  color: var(--main-color);
}

#modelinput {
  padding: 10px 20px;
  border: none;
  border-bottom: 1px solid black;
  font-size: 1.1em;
  text-align: center;
  background-color: transparent;
  width: 400px;
  max-width: 80%;
  margin: 60px 0;
}

#modelinput:focus {
  outline: none;
  border-bottom: 1px solid var(--main-color);
}

#modelinput::placeholder {
  color: #aaa;
}

#result {
  font-size: 0.9em;
  text-align: center;
  padding: 0 40px;
  max-width: 80%;
}

a:link,
a:visited,
a:active {
  color: var(--main-color);
  text-decoration: none;
  font-weight: bold;
}

a:hover {
  filter: brightness(130%);
}

#info {
  padding: 40px;
  max-width: min(80%, 700px);
}

#info-header {
  font-size: 0.9em;
  opacity: 0.5;
  font-weight: normal;
  font-style: italic;
  transition: opacity 300ms;
  cursor: pointer;
  text-align: center;
  margin-bottom: 50px;
}

#info-header:hover {
  opacity: 1;
}

#info-content {
  font-size: 0.75em;
  line-height: 1.6;
  text-align: justify;
  display: none;
  opacity: 0;
  transition: all 300ms;
  margin-bottom: 50px;
}

#info-content > p {
  margin-bottom: 30px;
}

#footer {
  background-color: var(--bg-color);
  width: 100%;
  text-align: center;
  position: fixed;
  bottom: 0;
  padding: 10px;
}

.footer-icon {
  margin: 10px;
  width: 30px;
  opacity: 0.4;
  transition: opacity 300ms;
  cursor: pointer;
}

.footer-icon:hover {
  opacity: 1;
}
